/**
 * Gets the favicon image url for a given url
 * https://dev.to/derlin/get-favicons-from-any-website-using-a-hidden-google-api-3p1e
 */

export const getFaviconSrc = (
  url: string,
  { size }: { size?: number } = {}
) => {
  const domain = new URL(url).hostname.replace('www.', '');
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=${size ?? 64}`;
};
