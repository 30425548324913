import * as React from 'react';

import { graphql, HeadFC, useStaticQuery } from 'gatsby';

import { SEO } from '../components/SEO';
import { ContentWrapper } from '../components/ContentWrapper';
import { PublicationCard } from '../components/Cards/Publication';
import { Overhead } from '../components/Overhead';
import { Layout } from '../components/Layout';
import { groupBy } from '../utils/groupBy';

const PublicationsPage = () => {
  const data = useStaticQuery<Queries.PublicationsQuery>(query);
  const details = data.allMarkdownRemark.nodes?.[0].frontmatter;

  const mutablePublications = [
    ...(details?.publications?.filter(p => !!p) ?? []),
  ] as {
    readonly title: string | null;
    readonly summary: string | null;
    readonly category: string | null;
    readonly url: string | null;
  }[];

  const groupedPublications = groupBy(mutablePublications, 'category');

  return (
    <Layout>
      <ContentWrapper className="mt-8 mb-40">
        <h1 className="text-4xl">Publications</h1>
        <p className="max-w-xl my-10 text-gray-500 dark:text-gray-400">
          Throughout time, I had several great opportunities to share my
          knowledge and thoughts on software, hardware, and security on several
          platforms.
        </p>
        <>
          {Object.entries(groupedPublications).map(([name, items], key) => {
            const sectionId = name.toLowerCase().split(' ').join('-');
            return (
              <section id={sectionId} key={key} className="mb-16">
                <Overhead className="text-gray-400 dark:text-gray-500 font-normal mb-4">{`${name}s`}</Overhead>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {items.map((publication, key) => (
                    <PublicationCard key={key} details={publication} />
                  ))}
                </div>
              </section>
            );
          })}
        </>
      </ContentWrapper>
    </Layout>
  );
};

export default PublicationsPage;

export const Head: HeadFC = () => <SEO title="Publications – Brendan Horan" />;

const query = graphql`
  query Publications {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/publications/" } }
    ) {
      nodes {
        frontmatter {
          publications {
            title
            summary
            category
            url
          }
        }
      }
    }
  }
`;
