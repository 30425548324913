import React from 'react';

import { BorderBackground } from '../BorderBackground';
import { Overhead } from '../Overhead';
import { ExternalLink } from '../ExternalLink';

import { getRootDomain } from '../../utils/getRootDomain';
import { getFaviconSrc } from '../../utils/getFaviconSrc';

type PublicationCardProps = {
  details: Queries.MarkdownRemarkFrontmatterPublications;
};

export const PublicationCard = (props: PublicationCardProps) => {
  const { url, category, summary, title } = props.details;

  const Favicon = url ? (
    <img src={getFaviconSrc(url)} alt="favicon" className="w-[80%]" />
  ) : undefined;

  const LinkedDomain = url ? (
    <ExternalLink
      href={url}
      className="block text-right font-medium text-xs text-gray-400 dark:text-gray-500"
      data-show-hint
    >
      {getRootDomain(url)}
    </ExternalLink>
  ) : undefined;

  return (
    <div className="relative overflow-hidden">
      <BorderBackground shape="rectangle" />
      <article className="relative p-3 w-full h-full flex flex-col justify-between bg-clip-padding bg-gray-100 dark:bg-gray-900 rounded-md border-[4px] border-transparent hover:border-primary-100/80 dark:hover:border-gray-800/80 transition-border duration-200">
        <div>
          <div className="flex flex-row justify-between items-center mb-4 gap-x-8">
            <Overhead
              data-article-category
              className="block text-xs text-primary-900 dark:text-primary-50"
            >
              {category}
            </Overhead>
            <div className="flex justify-center items-center w-6 h-6 bg-gray-50 dark:bg-gray-900 rounded-md">
              {Favicon}
            </div>
          </div>
          <span
            data-article-title
            className="block mb-4 font-medium text-primary-900 dark:text-primary-50"
          >
            {url ? <ExternalLink href={url}>{title}</ExternalLink> : title}
          </span>
          <p
            data-article-summary
            className="block mb-4 text-sm text-gray-500 dark:text-gray-400"
          >
            {summary}
          </p>
        </div>
        <div>{LinkedDomain}</div>
      </article>
    </div>
  );
};
